<!--
 * @Author: your name
 * @Date: 2020-06-03 15:07:14
 * @LastEditTime: 2020-12-30 17:00:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\production\particulars.vue
-->
<template>
  <div class="particu">
    <!--banner图-->
    <div class="banner">
      <!-- <img src="@/assets/images/banner1.png" alt /> -->
    </div>
    <div v-if="isshow" class="show">
      <h1>当前暂无商品</h1>
    </div>
    <!--详情主体-->
    <div class="main" v-if="datalist">
   
      <!--选择购买-->
      <div class="buy">
        <!--轮播图片-->
        <div class="left">
          <div class="imagestop">
            <!-- <img :src="picurl?piculs:datalist.pinfo.pic.picUrl" alt class="imagesmax" /> -->
           <!-- <img :src="picurl" alt class="imagesmax" /> -->
                <vue-photo-zoom-pro
                  :url="picurl"
                  class="imagesmax"
                  :scale='1.7'
                  :width='240'
                 :zoomer-style="{
                    'background-color': taobao ? '#fff' : 'rgba(0,0,0,0)'
                  }"
                  >
              </vue-photo-zoom-pro>
          </div>
          <div class="series_carousel">
            <div class="wrapper">
              <swiper :options="swiperOption">
                <swiper-slide v-for="item in (active.pics?active.pics:datalist.pinfo.pics)" :key="item.id" class="swip">
                  <img
                    :src="item.picUrl"
                    alt
                    class="image"
                    @click="picurl = item.picUrl"
                  />
                </swiper-slide>
                <!-- 前进后退 -->
              </swiper>
              <div class="swiper-prev forward">
                <img src="@/assets/images/左.png" alt />
              </div>
              <div class="swiper-next backwards">
                <img src="@/assets/images/右.png" alt />
              </div>
            </div>
          </div>
        </div>
        <!--收藏-->
        <div class="minimg">
          <img :src="datalist.iscare == 1 ? lovered : loveno" alt @click.stop="collect()" />
          <!-- <img src="@/assets/images/shop-car.png" alt @click="car()" /> -->
        </div>
        <!--数量系列选择-->
        <div class="right">
          <div class="wai">
            <!--title-->
            <div class="addcar">
              <h1>{{ datalist.pinfo.title }}</h1>
            </div>
            <!--价格-->
            <p class="pirce">{{active ? '￥' + active.priceStr : '￥'+ !active ? active.priceStr : datalist.pinfo.priceStr }}</p>
            <!-- <p class="pirce">{{'￥' + active.priceStr}}</p> -->
          </div>
          <!--详情说明-->
          <div class="state">{{ datalist.pinfo.desc }}</div>
          <!--主石选择-->
          <div class="els-button1">
            <span>主石选择</span>
            <el-select
              v-model="dskus"
              placeholder="请选择"
              popper-class="select-option"
              :popper-append-to-body="false"
              @change="tagFrame"
            >
              <el-option
                v-for="item in datalist.pinfo.dskus"
                :key="item.id"
                :label="item.caratStr+'ct' + item.shapeStr + item.colorStr"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!--钻饰款式-->
          <div class="els-button2">
            <span>钻饰款式</span>
            <el-select
              v-model="drskus"
              placeholder="请选择"
              popper-class="select-option"
              :popper-append-to-body="false"
              @change="tagFrame"
            >
              <el-option
                v-for="item in datalist.pinfo.drskus"
                :key="item.id"
                :label="item.skudesc + item.materialStr + item.weight+'g'"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!--尺寸-->
          <div class="els-button3">
            <span>选择尺寸</span>
            <el-select
              v-model="sizestr"
              placeholder="请选择"
              popper-class="select-option"
              :popper-append-to-body="false"
              @change="xzcc($event)"
            >
              <el-option
                v-for="item in datalist.pinfo.dtsizes"
                :key="item.id"
                :label="item.sizestr"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!--定制添加购物车-->
          <div class="carbut">
            <button @click="addcar($event)">添加至购物车</button>
            <button @click="made()">立即定制</button>
          </div>
        </div>
      </div>
     
      <div class="titlehead">
        <div></div>
        <h1>商品详情</h1>
        <div></div>
      </div>
      <!-- 商品详情 -->
      <div class="deli">
        <!-- 设计师头像 -->
        <div class="headimg" v-if="dinfo.designer">
          <img :src="dinfo.designer.head.picUrl" alt="">
        </div>
        <!-- 设计理念 -->
        <div class="concept" v-if="dinfo.designer">
            <p>设计理念</p>
            <p>DESIGN CONCEPT</p>
            <div class="bordes"></div>
            <div class="des">
                <img src="https://img.irisgem.com.cn/irisgem_1598348946897.jpg" alt="" v-if="dinfo.designer || dinfo.manuscriptpics">
                <img :src="dinfo.manuscriptpics.picUrl" alt="" v-else>
                <p v-if="dinfo.designerdesc">{{dinfo.designerdesc}}</p>
            </div>
        </div>
       <!-- 商品属性 -->
        <div class="nature">
            <p class="a">商品属性</p>
            <p class="b">COMMODITY ATTRLBUTES</p>
            <div class="bordes"></div>
            <div class="attr">
                <div class="a_left">
                  <img :src="picurl" alt="">
                </div>
                <div class="a_right">
                    <div>
                      <p>- 品牌名称：{{dinfo.brandName}}</p>
                      <p>- 主石范围：{{dinfo.mainstoneStr}}</p>
                      <p>- 副钻数量：{{dinfo.secondaryNumStr}}</p>
                      <p>- 副钻总重：{{dinfo.secondaryCtStr}}</p>
                      <p v-for="(item,index) in dinfo.materials" :key="index">
                        - 镶嵌材料：{{item}}
                      </p>
                    </div>
                </div>
            </div>
        </div>





      </div>
      <iview v-for="item in datalist.pinfo.imagetexts" :key="item.id" :data="item"></iview>

      <div class="recommend">
        <h1>相关推荐</h1>

        <div class="tzdiv">
          <div class="one" v-for="item in datalist.pushPros" :key="item.id">
            <img :src="item.pic.picUrl" alt />
            <p>{{item.title}}</p>
            <button @click="tzlist(item.id)">去看看</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { info, add,coInfo,collect } from "@/service/api.js";
export default {
  name: "particulars",
  components: {
    iview: () => import("./iview")
  },
  data() {
    return {
      taobao:true,
      datalist: "", //res数据
      drskus: "", //款式
      dskus: "", //主石选择
      sizestr: "", //尺寸
      sizes:false,//钻饰尺寸
      picurl: "", //主图片
      loveno: require("@/assets/images/loveccc.png"),
      lovered: require("@/assets/images/lovered.png"),
      price: false, //价格
      s: "",
      swiperOption: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        slidesPerView: 3,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          virtual: true
        },
        spaceBetween: 35,
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev"
        },
        ids: 0,

        //自动轮播
        // autoplay: {
        //   delay: 2000,
        //   //当用户滑动图片后继续自动轮播
        //   disableOnInteraction: false
        // }
        //开启循环模式
      },
      skuid: false,
      isshow: false,
      active:{},
      dinfo:[],//产品详情信息
      // headerimg:'',//设计师头像
      // designerdesc:'',//设计师寄语
      // brandname:'',//品牌名称
      // naubstibe:'',//主石范围
      // secondarynum:'',//副钻数量
      // secondaryct:'',//副石总重
    };
  },
  methods: {
    //尺寸
    xzcc(e){
      this.sizes = this.datalist.pinfo.dtsizes[e].id
      
    },
    //跳转
    tzlist(id) {
     window.location.href = `/productInfo.html?id=${id}`
    },
    //loading
    loading() {
    
      let loading = this.$loading({
        lock: true,
        text: "您还未登录，正在跳转登陆页面...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 300);
    },
    tagFrame() {
      var arr = this.datalist.pinfo.attributes;
      let vb = this.dskus + ":" + this.drskus;
      // console.log(vb);
      // let active = {};
      arr.forEach(res => {
        let index = res.propertis.indexOf(vb) != -1;
        if (index) {
          // active = res;
          this.active = res
        }
      });
     this.picurl  = this.active.pics?this.active.pics[0].picUrl:this.datalist.pinfo.pics[0].picUrl
      // if(this.active.pics){
      //   this.datalist.pinfo = { ...this.datalist.pinfo, ...this.active };
      // }else{
      //   this.datalist.pinfo = { ...this.active, ...this.datalist.pinfo };
      // }
      // console.log(active)
      // console.log(vb)
    //  this.datalist.pinfo = { ...this.datalist.pinfo, ...active };
    //  console.log(this.datalist.pinfo)
      // if (active) {
      //   if(active.pics){
      //     this.datalist.pinfo = { ...this.datalist.pinfo, ...active };
      //     // console.log(1)

      //   }else{
      //     this.datalist.pinfo = { ...active, ...this.datalist.pinfo };
      //     // console.log(2)
      //     // console.log(this.datalist.pinfo)
      //   }
         
      // }
      // console.log(active);
      this.skuid = this.active.id;
    },
    partget() {
      this.ids = this.$route.query.id;
      info({ productid: this.ids }).then(res => {
        if (res.attachment.pinfo) {
          var he = res.attachment.pinfo;
          this.dinfo = res.attachment.pinfo.dainfo
          console.log(this.dinfo)
          this.datalist = res.attachment;
          document.title = '臻品展示 ' +res.attachment.pinfo.title +' IRISGEM伊莉丝钻石品牌官网|专注于家庭情感的母婴钻饰定制品牌'
          // this.picurl = he.attributes.picUrl
          //   ? res.attachment.pinfo.attributes.picUrl
          //   : false;
          // this.skuid = he.attributes[0].id;
          this.sizestr = he.dtsizes[0].sizestr; //尺寸
          this.dskus = he.dskus[0].id;
          this.drskus = he.drskus[0].id;
          this.s = he.minpriceStr;
          console.log(this.datalist)
         let a = this.dskus + ":" + this.drskus;
         let arr = this.datalist.pinfo.attributes;
         arr.forEach(res => {
           let index = res.propertis.indexOf(a) != -1
           if(index){
             this.skuid = res.id
            //  this.price  = res.priceStr
            this.active = res
            console.log(this.active)
           }
        });
          this.picurl  = this.active.pics?this.active.pics[0].picUrl:this.datalist.pinfo.pics[0].picUrl

        } else {
          this.isshow = true;
        }
      });
    },
    //添加至购物车
    addcar(event) {
      if (this.$store.getters["user/getToken"]) {
        let ids = this.$route.query.id;
        let user = this.$store.getters["user/getuserdata"];
        add({
          uid: user.uid,
          pnum: 1,
          skuid: this.skuid,
          productid: ids,
          sizeid:this.sizes?this.sizes:this.datalist.pinfo.dtsizes[0].id
        }).then(res => {
          console.log(res);
          if (res.status == 200) {
            //购物车动画

            var offset = $(".spw").offset() || $('.cars').offset();
            var addcar = $(this);
            var img = $(".imagestop")
              .find("img")
              .attr("src");
            var flyer = $(
              '<img class="u-flyer" src="' + img + '" style="z-index:99999;border-radius: 50%;width:50px;height:50px">'
            );
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                // $("#msg")
                //   .show()
                //   .animate({ width: "250px" }, 200)
                //   .fadeOut(1000); //提示信息
                // addcar
                //   .css("cursor", "default")
                //   .removeClass("orange")
                //   .unbind("click");
              }
            });

            this.$store.commit("user/SETCAR", res.attachment.total);
          } else if (res.status == 402) {
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100
            });
          }
        });
      } else {
        this.loading();
        this.$router.push({
          path: "/createLogin.html?url=/productInfo.html?id=" + this.ids
        });
      }
    }, 
    //立即定制
    made() {
       if(this.$store.getters['user/getToken']){
              let propertis = {
            products: [
            ]
        }
        propertis.products.push({
            pid: this.datalist.pinfo.id,
            skuid: this.skuid,
            flag: 0,
            pnum: 1,
            sizeid:this.sizes?this.sizes:this.datalist.pinfo.dtsizes[0].id
          });

      
     coInfo({uid:this.$store.getters['user/getUid'], propertis: JSON.stringify(propertis), f: 2 }).then(res => {
      //  console.log(res)
      if(res.status === 400 ){
        return
      }else{
          this.$store.dispatch("order/orderInfo", res.attachment);
           let { href } = this.$router.resolve({
          path: "/confirmOrder" 
       });
      window.open(href, '_blank');
        // this.$router.push({
        //   path: href
        // });
      }
        // if (res.status === 200) {
        //   this.$notify({
        //     title: "成功",
        //     message: "您已成功添加此商品",
        //     type: "success",
        //     offset: 100
        //   });
        // }
      });
       }else{
            this.loading();
        this.$router.push({
          path: "/createLogin.html?url=/productInfo.html?id=" + this.ids
        });
       }



    },
    //收藏
    collect() {
      // if (this.$store.getters["user/getToken"]) {
      //   this.loading();
      //   this.$router.push({ path: "/collect" });
      // } else {
      //   this.loading();
      //   this.$router.push({
      //     path: "/login?url=/particulars?ids=" + this.ids
      //   });
      // }
      console.log(this.datalist)
       if (this.$store.getters["user/getToken"]) {
        let type = this.datalist.iscare == 1 ? 2 : 1;
        collect({
          type:type,
          productid: this.datalist.pinfo.id
        }).then((res) => {
          console.log(res)
          this.datalist.iscare = type;
        });
      } else {
        this.loading();
        this.$router.push({
          path: "/createLogin.html?url=/productInfo.html?id=" + this.ids
        });
      } 
    },
    // car() {
    //   if (this.$store.getters["user/getToken"]) {
    //     this.$router.push({ path: "/shopping" });
    //   } else {
    //     this.$router.push({ path: "/login" });
    //   }
    // }
  },
  watch: {
    "$route.query.ids": function(to) {
      this.ids = to;
      this.partget();
    },
  },
  mounted() {
    this.partget();
    // console.log(this.$route.params.ids);
  }
};
</script>
<style lang="less">
.container .origin-img{
  object-fit: cover !important;
}  
</style>
<style lang="less" scoped>
.deli{
  width: 960px;
  min-width: 750px;
  margin: 100px auto;
}
.headimg{
  margin: 0 auto;
  width: 241px;
  height: 241px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.attr{
  width: 840px;
  height: 650px;
  border: 2px solid #937D4B;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.a_left{
  width: 49%;
  height: 100%;
  border-right: 2px solid #937D4B;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 366px;
    height: 307px;
    object-fit: cover;
  }
}
.a_right{
  width: 50%;
  height: 100%;
  position:relative;
  div{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  p{
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 50px;
    text-align: left;
  }
}
.nature{
  text-align: center;
  margin-top:80px ;
  .a{
    font-size: 37px;
    font-family: Songti SC;
    font-weight: bold;
    color: #404040;
    height: 60px;
    line-height: 60px
  };
 .b{
    font-size: 17px;
    font-family: Songti SC;
    font-weight: 400;
    color: #404040;
  };
  .bordes{
    height: 1px;
    background: #DAD9DD;
    margin: 40px auto;
  }
  .des{
    img{
      width: 800px;
      height: 280px;
      object-fit: cover;
    }
    p{
      width: 760px;
      line-height: 30px;
      margin: 30px auto;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #2C2C2C;
    }
  }
}

.concept{
  text-align: center;
  margin-top:80px ;
  p:nth-child(1){
    font-size: 37px;
    font-family: Songti SC;
    font-weight: bold;
    color: #404040;
    height: 60px;
    line-height: 60px
  };
  p:nth-child(2){
    font-size: 17px;
    font-family: Songti SC;
    font-weight: 400;
    color: #404040;
  };
  .bordes{
    height: 1px;
    background: #DAD9DD;
    margin: 40px auto;
  }
  .des{
    img{
      width: 800px;
      height: 280px;
      object-fit: cover;
    }
    p{
      width: 760px;
      line-height: 30px;
      margin: 30px auto;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #2C2C2C;
    }
  }
}















.u-flyer {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: fixed;
  z-index: 9999;
}
.particu {
  width: 100%;
  .banner {
    width: 100%;
    height: 50px;
    background: #f5f5f5;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.main {
  width: 63%;
  margin: 50px auto;
}
.buy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 40%;
    height: 100%;
    .imagestop {
      width: 100%;
      height: 430px;
      overflow: hidden;
      .imagesmax {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .right {
    width: 54%;
    height: 100%;
  }
}
.series_carousel {
  width: 85%;
  position: relative;
  margin: 30px auto;
  img {
    width: 80px;
    height: 80px;
  }
  .forward {
    width: 0.9vw;
    height: 1.3vw;
    position: absolute;
    left: -6%;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    outline: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .backwards {
    width: 0.9vw;
    height: 1.3vw;
    position: absolute;
    right: -6%;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    outline: none;
    img {
      width: 90%;
      height: 90%;
    }
  }
}
.show {
  height: 800px;
  width: 100%;
  text-align: center;
  line-height: 800px;
}
.series_carousel .wrapper {
  .image {
    width: 110px;
    height: 110px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.wai {
  width: 98%;
  margin: 0 auto;
}
.minimg {
  width: 1%;
  height: 100%;
  img:last-child {
    margin-bottom: -2vw;
  }
}
.addcar {
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  h1 {
    width: 95%;
    height: 100%;
    font-size: 48px;
    font-weight: 500;
    overflow: hidden;
  }
}
.pirce {
  color: #999999;
  font-size: 0.9vw;
  line-height: 3vw;
}
.state {
  width: 100%;
  height: 120px !important; /* no */
  font-size: 16px; /* no */
  line-height: 30px; /* no */
  max-height: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.els-button {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  span {
    color: #999999;
    font-size: 16px;
  }
}
.els-button1 /deep/ .el-input__inner {
  width: 170px;
  border-radius: 30px;
  border: 1px solid #921d22;
  margin-left: 20px;
  margin-top: 30px;
}
.els-button1 /deep/ .el-input__suffix {
  top: 13px;
}

.els-button1 /deep/ .select-option {
  border-radius: 30px;
  overflow: hidden;
  margin-left: 16px;
}
.els-button1 /deep/ .el-select-dropdown__item.selected {
  color: #921d22;
}
.el-select-dropdown__item{
  font-size: 16px;/*no*/
  height: 34px;/*no*/
}
.els-button2 /deep/ .el-input__inner {
  width: 210px;
  border-radius: 30px;
  border: 1px solid #921d22;
  margin-left: 20px;
  margin-top: 20px;
}

.els-button2 /deep/ .select-option {
  border-radius: 30px;
  overflow: hidden;
  margin-left: 16px;
}
.els-button2 /deep/ .el-select-dropdown__item.selected {
  color: #921d22;
}

.els-button3 /deep/ .el-input__inner {
  width: 120px;
  border-radius: 30px;
  border: 1px solid #921d22;
  margin-left: 20px;
  margin-top: 20px;
}

.els-button3 /deep/ .select-option {
  border-radius: 30px;
  overflow: hidden;
  margin-left: 16px;
}
.els-button3 /deep/ .el-select-dropdown__item.selected {
  color: #921d22;
}
.els-button2 /deep/ .el-select__caret {
  line-height: 50px;
  margin-top: 10px;
}
.els-button3 /deep/ .el-select__caret {
  line-height: 50px;
  margin-top: 10px;
}
.carbut {
  width: 48%;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  button {
    width: 180px;
    height: 50px;
    color: #fff;
    background: #921d22;
    border: none;
    outline: none;
    border-radius: 50px;/*no*/
    font-size: 14px;
  }
  button:last-child {
    margin-left: 24px;
  }
}

el-select-dropdown__list {
  background: #cfcfcf;
}
.recommend {
  width: 85%;
  height: 566px; /* no */
  background: #222328;
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 100px;
  h1 {
    color: #fff;
    font-size: 26px; /* no */
    font-weight: 500;
    text-align: center;
    padding-top: 2vw;
  }
  p {
    color: #cfcfcfcf;
    width: 50%;
    text-align: center;
    margin: 1vw auto;
    font-size: 0.7vw;
  }
  .tzdiv {
    width: 566px; /* no */
    height: 320px; /* no */
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 30px auto;
    .one {
      width: 275px; /* no */
      height: 445px; /* no */
      background: #fff;
      text-align: center;
      overflow: hidden;
      margin-top: 80px;
      &:last-child {
        border-radius: 0 10px 10px 0px; /* no */
      }
      &:first-child {
        border-radius: 10px 0px 0px 10px; /* no */
      }
      img {
        width: 100%; /* no */
        height: 300px; /* no */
        object-fit: cover;
      }
      p {
        color: #000;
        text-align: center;
        font-size: 0.8vw; /* no */
        line-height: 22px; /* no */
      }
      button {
        width: 150px; /* no */
        height: 35px; /* no */
        line-height: 35px; /* no */
        outline: none;
        border: none;
        border-radius: 20px; /* no */
        border: 1px solid #921d22;
        color: #921d22;
        background: #ffffff;
        font-size: 14px; /* no */
      }
    }
  }
}
.swiper-slide {
  img {
    object-fit: cover;
  }
}
.titlehead {
  width: 18%;
  color: #999999;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  font-size: 30px;
  div {
    width: 4%;
    height: 1px;
    border: 1px #cfcfcf solid;
  }
  h1 {
    font-weight: 500;
    font-size: 30px;
  }
}

@media screen and(max-width:1700px) {
  .particu .main {
    width: 1100px; /* no */
    .left .imagestop {
      height: 446px; /* no */
      width: 446px; /* no */
    }
    h1 {
      line-height: 65px; /* no */
    }
    .pirce {
      margin: 5px 0; /* no */
      font-size: 18px; /* no */
    }
    .state {
      height: 120px !important; /* no */
      line-height: 32px; /* no */
      font-size: 16px; /* no */
    }
    .addcar {
      height: 70px; /* no */
    }
    .els-button1 {
      margin-top: 30px !important; /* no */
    }
    .swip {
      width: 105px !important; /* no */
      height: 105px; /* no */
      .image {
        width: 100%; /* no */
        height: 100%; /* no */
      }
    }
    .series_carousel {
      width: 395px; /* no */
      margin-left: 25px; /* no */
    }
    .carbut {
      margin-top: 5px; /* no */
      button {
        width: 141px; /* no */
        height: 49px; /* no */
      }
    }
    .els-button1 {
      font-size: 14px; /* no */
    }
    .els-button2 {
      font-size: 14px; /* no */
    }
    .els-button3 {
      font-size: 14px; /* no */
    }
    .els-button1 /deep/ .el-input__inner {
      width: 120px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
    .els-button2 /deep/ .el-input__inner {
      width: 180px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
    .els-button3 /deep/ .el-input__inner {
      width: 70px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
  }
}
@media screen and(max-width:1620px) {
  .particu .main {
    width: 1100px; /* no */
  }
}
@media screen and(max-width:1420px) {
  .particu .main {
    width: 78%; /* no */
  }
}
@media screen and(max-width:1500px) {
  .particu .main {
    width: 1100px; /* no */
    .left .imagestop {
      height: 450px; /* no */
      width: 450px; /* no */
    }
    h1 {
      line-height: 100px; /* no */
    }
    .state {
      height: 135px !important; /* no */
      line-height: 34px; /* no */
      font-size: 16px; /* no */
    }
    .addcar {
      height: 90px; /* no */
      h1 {
        font-size: 44px; /* no */
      }
    }
    .els-button1 {
      font-size: 14px; /* no */
    }
    .els-button2 {
      font-size: 14px; /* no */
    }
    .els-button3 {
      font-size: 14px; /* no */
    }
    .els-button1 /deep/ .el-input__inner {
      width: 120px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
    .els-button2 /deep/ .el-input__inner {
      width: 180px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
    .els-button3 /deep/ .el-input__inner {
      width: 70px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 35px; /* no */
    }
    .series_carousel {
      width: 395px; /* no */
      margin-left: 25px; /* no */
    }
    .swip {
      width: 105px !important; /* no */
      height: 105px; /* no */
      .image {
        width: 100%; /* no */
        height: 100%; /* no */
      }
    }

    .carbut {
      button {
        width: 141px; /* no */
        height: 49px; /* no */
      }
    }
  }
}

@media screen and(max-width:1300px) {
  .particu .main {
    width: 1050px; /* no */
    .left .imagestop {
      height: 430px; /* no */
      width: 430px; /* no */
    }
    h1 {
      line-height: 100px; /* no */
    }
    .state {
      height: 150px !important; /* no */
      line-height: 32px; /* no */
      font-size: 16px; /* no */
    }
    .addcar {
      height: 90px; /* no */
      h1 {
        font-size: 44px; /* no */
      }
    }
    .els-button1 {
      font-size: 14px; /* no */
    }
    .els-button2 {
      font-size: 14px; /* no */
    }
    .els-button3 {
      font-size: 14px; /* no */
    }
    .els-button1 /deep/ .el-input__inner {
      width: 120px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button2 /deep/ .el-input__inner {
      width: 180px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button3 /deep/ .el-input__inner {
      width: 70px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .series_carousel {
      width: 380px; /* no */
      margin-left: 25px; /* no */
    }
    .swip {
      width: 100px !important; /* no */
      height: 100px; /* no */
      .image {
        width: 100%; /* no */
        height: 100%; /* no */
      }
    }
  }
}
@media screen and(max-width:1100px) {
  .particu .main {
    width: 1000px; /* no */
    .left .imagestop {
      height: 410px; /* no */
      width: 410px; /* no */
    }
    h1 {
      line-height: 100px; /* no */
    }
    .state {
      height: 150px !important; /* no */
      line-height: 28px; /* no */
      font-size: 16px; /* no */
    }
    .addcar {
      height: 90px; /* no */
      h1 {
        font-size: 44px; /* no */
      }
    }
    .els-button1 {
      font-size: 14px; /* no */
    }
    .els-button2 {
      font-size: 14px; /* no */
    }
    .els-button3 {
      font-size: 14px; /* no */
    }
    .els-button1 /deep/ .el-input__inner {
      width: 120px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button2 /deep/ .el-input__inner {
      width: 180px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button3 /deep/ .el-input__inner {
      width: 70px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .series_carousel {
      width: 360px; /* no */
      margin-left: 25px; /* no */
    }
    .swip {
      width: 90px !important; /* no */
      height: 90px; /* no */
      .image {
        width: 100%; /* no */
        height: 100%; /* no */
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .particu .main {
    width: 950px; /* no */
    .left .imagestop {
      height: 390px; /* no */
      width: 390px; /* no */
    }
    h1 {
      line-height: 100px; /* no */
    }
    .state {
      height: 150px !important; /* no */
      line-height: 28px; /* no */
      font-size: 16px; /* no */
    }
    .addcar {
      height: 90px; /* no */
      h1 {
        font-size: 44px; /* no */
      }
    }
    .els-button1 {
      font-size: 14px; /* no */
    }
    .els-button2 {
      font-size: 14px; /* no */
    }
    .els-button3 {
      font-size: 14px; /* no */
    }
    .els-button1 /deep/ .el-input__inner {
      width: 120px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button2 /deep/ .el-input__inner {
      width: 180px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .els-button3 /deep/ .el-input__inner {
      width: 70px; /* no */
      border-radius: 30px; /* no */
      border: 1px solid #921d22; /* no */
      margin-left: 20px; /* no */
      font-size: 14px; /* no */
      height: 32px; /* no */
    }
    .series_carousel {
      width: 340px; /* no */
      margin-left: 25px; /* no */
    }
    .swip {
      width: 80px !important; /* no */
      height: 80px; /* no */
      .image {
        width: 100%; /* no */
        height: 100%; /* no */
      }
    }
  }
}
</style>
